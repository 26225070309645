<template>
    <CBadge :color="statusClass(row.value)" v-if="row.value">
        {{ $t('offering.fulfilment.' + row.value) }}
    </CBadge>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'type': [String],
    },
    methods: {
        statusClass(status) {
            if (status == 'partial') {
                return 'warning';
            } else if (status == 'complete') {
                return 'success'
            } else if (status == 'none') {
                return 'danger'
            } else {
                return 'light';
            }
        },
    }
}
</script>